import React from 'react';
import { Button } from 'reactstrap';

export const AcademicInternship: React.FC = () => (
  <div className="container-xxl service py-1">
    <div className="container">
      <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
        <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">AI-Driven Talent Solutions</p>
        <h1 className="display-5 mb-5">Smart hiring with AI-powered screening, skill matching, and customized training for seamless workforce integration.</h1>
      </div>
      <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="col-lg-4">
          <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-1"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />AI-Powered Screening</h5>
            </Button>
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-2"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Skill Matching</h5>
            </Button>
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-3"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Customized Training</h5>
            </Button>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="tab-content w-100">
            <div className="tab-pane fade show active" id="tab-pane-1">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/proj-gen.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">AI-Powered Screening</h3>
                  <p className="mb-4">Leverage artificial intelligence to streamline hiring processes, automatically filter top candidates, and ensure the best fit for your organization.</p>
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-2">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/bootcamp.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">Skill Matching</h3>
                  <p className="mb-4">Match candidates with the right job roles based on advanced AI algorithms, ensuring efficiency and a high success rate.</p>
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-3">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/ws-ide.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">Customized Training</h3>
                  <p className="mb-4">Enhance workforce skills with personalized training programs designed to bridge gaps and optimize performance.</p>
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AcademicInternship;
