import {
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
// import { useNavigate } from 'react-router';
import { faCopyright, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import './home.scss';

export const WDSFooter: React.FC = () => (
  <div className="footer pt-3">
    <Row className="mx-2 pt-4">
      <Col lg="9">
        <div className="text-footer-know-campus">
          <span className="mb-2 ">
            We have a diverse portfolio that encompasses cloud development and a comprehensive array of services designed to empower businesses in attaining their objectives
          </span>
        </div>
        {/* <div className="my-5">
            <Button onClick={() => { history('/contact-us'); window.scrollTo(0, 0); }} className="intro-btn-filled">Connect with us</Button>
          </div> */}
      </Col>
      <Col lg="1" className="my-2" />
      {/* <Col lg="3" className="my-2">
            <div className="text-footer-abou-count">
              <span>50,000 +</span>
            </div>
            <div className="text-footer-abou-title">
              <span>Members</span>
            </div>
          </Col>
          <Col lg="3" className="my-2">
            <div className="text-footer-abou-count">
              <span>100 +</span>
            </div>
            <div className="text-footer-abou-title">
              <span>Website Visit</span>
            </div>
          </Col>
          <Col lg="3" className="my-2">
            <div className="text-footer-abou-count">
              <span>100 +</span>
            </div>
            <div className="text-footer-abou-title">
              <span>Organizations</span>
            </div>
          </Col> */}
    </Row>
    <Row className="mx-2 pt-5">
      <Col lg="3" className="my-2">
        <div className="align-start">
          <img width="80px" src="/images/common/footer-follow-us.png" alt=".." />
        </div>
        <div className="align-start pt-2">
          <div className="mb-2 mx-1">
            <Button
              className="btn-footer-icon align-center"
              color="default"
              href="https://www.linkedin.com/company/wdsinfotech"
              rel="noreferrer"
              id="tooltiplinkedin"
              target="_blank"
            >
              <i className="fab"><FontAwesomeIcon icon={faLinkedinIn} className="footer-wds-icon" /></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltiplinkedin">
              Linkedin
            </UncontrolledTooltip>
          </div>
          {/* <div className="mb-2 mx-1">
            <Button
              className="btn-footer-icon align-center"
              color="default"
              href="https://twitter.com/#"
              id="tooltiptweet"
              target="_blank"
            >
              <i className="fab"><FontAwesomeIcon icon={faTwitter} className="footer-wds-icon" /></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltiptweet">
              Twitter
            </UncontrolledTooltip>
          </div>
          <div className="mb-2 mx-1">
            <Button
              className="btn-footer-icon align-center"
              color="default"
              href="https://www.facebook.com/#"
              id="tooltipFb"
              target="_blank"
            >
              <i className="fab"><FontAwesomeIcon icon={faFacebookF} className="footer-wds-icon" /></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltipFb">
              Facebook
            </UncontrolledTooltip>
          </div>
          <div className="mb-2 mx-1">
            <Button
              className="btn-footer-icon align-center"
              color="default"
              href="https://www.instagram.com/#"
              id="tooltipInsta"
              target="_blank"
            >
              <i className="fab"><FontAwesomeIcon icon={faInstagram} className="footer-wds-icon" /></i>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltipInsta">
              Instagram
            </UncontrolledTooltip>
          </div> */}
        </div>
      </Col>
      <Col lg="1" className="my-2" />
      <Col lg="3" className="my-2">
        <div className="align-start">
          <span className="mb-2 text-footer-header">
            About
          </span>
        </div>
        <div className="pt-1">
          <span className="mb-2 text-footer-desc">
            Our goal is to provide emerging ventures with a comprehensive, hassle-free solution that helps them focus on what really matters - realizing their vision.
          </span>
        </div>
      </Col>
      <Col lg="2" className="my-2" />
      <Col lg="3" className="my-2">
        <div className="align-start">
          <span className="mb-2 text-footer-header">
            Join us
          </span>
        </div>
        <div>
          <span className="mb-5">
            <p className="text-footer-desc"> <i className="fa fa-map-marker" /> {' '} WDSInfotech, Pune & Bengaluru<br />
              <FontAwesomeIcon icon={faEnvelope} /> {' '} info@wdsinfotech.com
            </p>
          </span>
        </div>
      </Col>
      <Col xs="12">
        <div className="align-center pt-5 my-3">
          <span className="copyright">
            copyright {' '}<FontAwesomeIcon icon={faCopyright} />{' '}
            <a
              href="/"
              className="text-copyright-mail"
              rel="noreferrer"
              target="_blank"
              style={{
                marginLeft: '2px', marginRight: '2px', fontSize: '15px', textDecoration: 'none',
              }}
            >
              wdsinfotech
            </a>
            {' '} 2020
          </span>
        </div>
      </Col>
    </Row>
  </div>
);
export default WDSFooter;
