import React from 'react';

export const AboutUs: React.FC = () => (
  <div className="container-xxl">
    <div className="container">
      <div className="row g-4 align-items-end mb-4">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <img alt="" className="img-fluid rounded" src="/images/public/about.jpg" />
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">About Us</p>
          <h1 className="display-5 mb-4">AI-Driven Talent, Tech-Enabled Solutions</h1>
          <p className="mb-4">At WDSInfotech, we specialize in developing innovative in-house products and providing top-tier consultancy services. Our AI-driven talent screening and customized training ensure that clients get the best professionals without the hassle of evaluation. With a strong focus on technology and excellence, we craft tailored solutions that drive business growth and success.
          </p>
          <div className="border rounded p-3">
            <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  className="nav-link fw-semi-bold active font-primary"
                  id="nav-story-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-story"
                  type="button"
                  role="tab"
                  aria-controls="nav-story"
                  aria-selected="true"
                >Story
                </button>
                <button
                  className="nav-link fw-semi-bold font-primary"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="false"
                >Mission
                </button>
                <button
                  className="nav-link fw-semi-bold font-primary"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >Vision
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-story"
                role="tabpanel"
                aria-labelledby="nav-story-tab"
              >
                <p className="mb-0">Fueled by a passion for innovation and a commitment to excellence, WDSInfotech set out to redefine the digital landscape. From our humble beginnings, our team of visionaries and technologists has continuously pushed boundaries—turning challenges into opportunities and obstacles into stepping stones for success. Today, we empower businesses with AI-driven talent solutions and cutting-edge technology, shaping the future of work and digital transformation.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <p className="mb-0">We provide top-tier software development and consultancy services, refining the best talent through AI-powered screening and customized training. Our goal is to ensure clients get skilled professionals without the hassle of talent evaluation.
                </p>
              </div>
              <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                <p className="mb-0">To become a leading provider of AI-driven hiring solutions and innovative in-house products that revolutionize recruitment, training, and digital transformation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="row g-4">
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/projects.png" alt="linkdein" />
                </div>
                <div className="ps-3">
                  <h4>AI-Driven Talent Solutions</h4>
                  <span>Smart hiring with AI-powered screening, skill matching, and customized training for seamless workforce integration</span>
                </div>
                <div className="border-end d-none d-lg-block" />
              </div>
              <div className="border-bottom mt-4 d-block d-lg-none" />
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/engineering.png" alt="linkdein" />
                </div>
                <div className="ps-3 mr-2">
                  <h4>Cutting-Edge In-House Products</h4>
                  <span>Innovative, scalable solutions designed to enhance business efficiency and drive digital transformation</span>
                </div>
                <div className="border-end d-none d-lg-block" />
              </div>
              <div className="border-bottom mt-4 d-block d-lg-none" />
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/tech-softskill.png" alt="linkdein" />
                </div>
                <div className="ps-3">
                  <h4>Tech-Enabled Business Consulting</h4>
                  <span>Expert guidance in AI, automation, and cloud solutions to optimize processes and future-proof businesses</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
