import React from 'react';

export const Products: React.FC = () => (
  <div className="container-xxl feature py-5">
    <div className="container py-5">
      <div className="row g-5 align-items-center py-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Our Products & Services</p>
          <h1 className="display-5 mb-4">Empowering Businesses with Innovation & Talent</h1>
          <p className="mb-4">
            From cutting-edge in-house products to AI-driven talent solutions, we deliver efficiency, scalability, and unmatched expertise.
            Our services cater to businesses seeking skilled professionals, seamless project execution, and future-ready solutions.
          </p>
          <a className="btn btn-primary py-3 px-5" href="/products">Explore Our Offerings</a>
        </div>
        <div className="col-lg-6">
          <div className="row g-4 align-items-center">
            <div className="col-md-6">
              <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">AI-Powered Talent Solutions</h4>
                    <p className="mb-3">
                      Our advanced AI-driven screening system ensures you get the right talent—be it freelancers, contractors, or full-time hires—without the hiring hassle.
                    </p>
                  </div>
                </div>
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Custom In-House Products</h4>
                    <p className="mb-3">
                      We develop cutting-edge, scalable digital solutions designed to enhance business productivity, automate workflows, and drive growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="feature-box border rounded p-4">
                <i className="fa fa-check fa-3x text-primary mb-3" />
                <h4 className="mb-3">End-to-End Project Execution</h4>
                <p className="mb-3">
                  From consultation to implementation, we deliver tech-driven business solutions, ensuring seamless execution and long-term success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Products;
